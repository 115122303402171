import Swiper from 'swiper';

document.addEventListener('click', e => {
    const btn = e.target.closest('[data-nav-links-btn-toogle]');

    if (btn) {
        const wrapper = btn.closest('[data-nav-links-wrapper]');
        const items = wrapper.querySelectorAll('[data-nav-links-item]');

        items.forEach(item => {
            item.classList.toggle('active');
        })
        btn.classList.toggle('active');
    }
})

document.addEventListener('click', e => {
    const btn = e.target.closest('[data-btn-go-to-header]');

    if (btn) {
        window.scrollTo({top: 0, behavior: "smooth"})
    }
})



